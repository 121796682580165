var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "vue-dropzone",
            {
              ref: "termsBulkUpload",
              attrs: {
                id: "terms-bulk-upload-file-input",
                options: _vm.dropzoneOptions,
                "use-custom-slot": true,
              },
              on: {
                "vdropzone-max-files-exceeded": _vm.onMaxFilesExceeded,
                "vdropzone-success": _vm.onDropzoneSuccess,
                "vdropzone-canceled": _vm.onDropzoneCancel,
                "vdropzone-error": _vm.handleError,
                "vdropzone-removed-file": _vm.onDropzoneCancel,
              },
            },
            [
              _c("div", { staticClass: "text-center text-muted" }, [
                _c("p", { staticClass: "small mb-0" }, [
                  _vm._v(" Drag and drop your CSV file here, "),
                ]),
                _c("p", { staticClass: "small" }, [
                  _vm._v(" or click to select a file from your computer. "),
                ]),
              ]),
            ]
          ),
          _vm.fileUploadError
            ? _c("el-alert", {
                staticClass: "mt-2",
                attrs: {
                  type: "error",
                  title: "Unable to Delete",
                  description: _vm.fileUploadError,
                  closable: false,
                  "show-icon": "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h6", [
          _vm._v(
            " Get started deleting multiple search terms from your catalog: "
          ),
        ]),
        _c("ol", { staticClass: "m-0" }, [
          _c(
            "li",
            [
              _c(
                "el-button",
                {
                  staticClass: "text-info p-0",
                  attrs: { type: "text" },
                  on: { click: _vm.onDownloadTemplate },
                },
                [
                  _vm._v(
                    " Download our template to help delete your search terms. "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._m(0),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _vm._v(" For each term, make sure to enter a value for "),
      _c("strong", [_vm._v("source")]),
      _vm._v(" and "),
      _c("strong", [_vm._v("term")]),
      _vm._v(". These are required. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }