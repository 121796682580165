var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row d-flex justify-content-center" }, [
    _c(
      "div",
      { staticClass: "col-md-10" },
      [
        _c(
          "simple-wizard",
          {
            attrs: {
              loading: _vm.isLoading,
              "finish-button-text": "Upload",
              "hide-navigation": true,
            },
            on: {
              "cancel-wizard": () => _vm.$router.push("/catalog-management"),
              "wizard-complete": _vm.wizardComplete,
            },
          },
          [
            _c("template", { slot: "header" }, [
              _c("h4", { staticClass: "card-title mb-0" }, [
                _vm._v(" " + _vm._s(_vm.formHeader) + " "),
              ]),
            ]),
            !_vm.isBulk
              ? _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": () =>
                        _vm.validateStep("single-upload-term"),
                    },
                  },
                  [
                    _c("single-upload-term", {
                      ref: "single-upload-term",
                      on: { "on-validated": _vm.onStepValidated },
                    }),
                    _vm.uploadError
                      ? _c("el-alert", {
                          staticClass: "mt-2",
                          attrs: {
                            type: "error",
                            title: "Unable to add search term",
                            description: _vm.uploadError,
                            closable: false,
                            "show-icon": "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isBulk
              ? _c(
                  "wizard-tab",
                  {
                    attrs: {
                      "before-change": () =>
                        _vm.validateStep("bulk-upload-term"),
                    },
                  },
                  [
                    _c("bulk-upload-term", {
                      ref: "bulk-upload-term",
                      on: { "on-validated": _vm.onStepValidated },
                    }),
                    _vm.uploadError
                      ? _c("el-alert", {
                          staticClass: "mt-2",
                          attrs: {
                            type: "error",
                            title: "Unable to upload search terms",
                            description: _vm.uploadError,
                            closable: false,
                            "show-icon": "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "text-center mt-3" },
          [
            _vm._v(" Need more help? "),
            _c(
              "el-button",
              {
                staticClass: "text-info",
                attrs: { type: "text" },
                on: { click: _vm.handleHelp },
              },
              [_vm._v(" Read about search terms. ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }