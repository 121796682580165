var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("label", [_vm._v(" Select services ")]),
    _c(
      "div",
      _vm._l(_vm.serviceList, function (service, index) {
        return _c("div", { key: index, staticClass: "form-check" }, [
          _c("label", { staticClass: "form-check-label mb-2" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.services,
                  expression: "model.services",
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: '"required"',
                },
              ],
              staticClass: "form-check-input",
              attrs: { type: "checkbox", name: "services" },
              domProps: {
                value: service,
                checked: Array.isArray(_vm.model.services)
                  ? _vm._i(_vm.model.services, service) > -1
                  : _vm.model.services,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.model.services,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = service,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.model, "services", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.model,
                            "services",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.model, "services", $$c)
                    }
                  },
                  _vm.validate,
                ],
              },
            }),
            _c("span", { staticClass: "form-check-sign" }),
            _c("span", { staticClass: "text-capitalize" }, [
              _vm._v(_vm._s(service)),
            ]),
          ]),
        ])
      }),
      0
    ),
    _c("small", { staticClass: "form-text text-muted" }, [
      _vm._v(" Select which services you wish to upload your listing(s) to "),
    ]),
    _c(
      "small",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.errors.has("services"),
            expression: 'errors.has("services")',
          },
        ],
        staticClass: "form-text text-danger",
      },
      [_vm._v(" Please select at least one service ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }