var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h6", [_vm._v(" Listing Information ")]),
      _c(
        "div",
        { staticClass: "d-flex flex-column mb-4" },
        [
          _c("label", { attrs: { for: "source-select" } }, [
            _vm._v(" Listing source "),
          ]),
          _c(
            "el-select",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.source,
                  expression: "modelValidations.source",
                },
              ],
              class: { invalid: _vm.getError("source") },
              attrs: {
                id: "source-select",
                name: "source",
                placeholder: "Select listing source",
                filterable: "",
                clearable: "",
              },
              model: {
                value: _vm.model.source,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "source", $$v)
                },
                expression: "model.source",
              },
            },
            _vm._l(_vm.sourceOptions, function (option, optionIndex) {
              return _c("el-option", {
                key: optionIndex,
                attrs: {
                  label: option.label,
                  value: option.value,
                  disabled: option.disabled,
                },
              })
            }),
            1
          ),
          _vm.getError("source")
            ? _c("div", { staticClass: "form-text text-danger" }, [
                _c("small", [_vm._v(" The listing source is required. ")]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "base-input",
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: _vm.modelValidations.listing,
              expression: "modelValidations.listing",
            },
          ],
          attrs: {
            label: "Listing ID",
            name: "listing",
            required: "",
            placeholder: "Enter Listing ID",
            error: _vm.getError("listing"),
          },
          model: {
            value: _vm.model.listing,
            callback: function ($$v) {
              _vm.$set(_vm.model, "listing", $$v)
            },
            expression: "model.listing",
          },
        },
        [
          _vm.getError("listing")
            ? _c(
                "div",
                {
                  staticClass: "form-text text-danger",
                  attrs: { slot: "error" },
                  slot: "error",
                },
                [_c("small", [_vm._v(" The listing ID is required. ")])]
              )
            : _vm._e(),
        ]
      ),
      _vm.selectedServices.indexOf("reviewbox") > -1
        ? _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "label",
                { staticClass: "d-flex align-items-center" },
                [
                  _vm._v(" Sync with Bazaarvoice? "),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "ml-1",
                      attrs: { placement: "right", "open-delay": 500 },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "rb-help-btn text-muted cursor-pointer",
                        },
                        [
                          _c("info-icon", {
                            staticClass: "rb-icon",
                            attrs: { title: "" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _c("p", [
                            _vm._v(
                              " Respond to customer reviews and questions with your associated Bazaarvoice account. "
                            ),
                          ]),
                          _c("p", [
                            _vm._v(
                              " This requires a Bazaarvoice integration. Want to learn more? "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://helpcenter.getreviewbox.com/kb-tickets/new",
                                  target: "_blank",
                                },
                              },
                              [_vm._v("Contact us")]
                            ),
                            _vm._v(". "),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.isBazaarvoice,
                    callback: function ($$v) {
                      _vm.isBazaarvoice = $$v
                    },
                    expression: "isBazaarvoice",
                  },
                },
                [_vm._v(" Yes ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedServices.indexOf("pricebox") > -1
        ? [
            _c("h6", [_vm._v(" Pricebox Information ")]),
            _c("base-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.mapViolations,
                  expression: "modelValidations.mapViolations",
                },
              ],
              attrs: {
                label: "Low MAP",
                name: "low map violation",
                placeholder: "Enter low MAP",
                error: _vm.getError("low map violation"),
              },
              model: {
                value: _vm.model.low,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "low", $$v)
                },
                expression: "model.low",
              },
            }),
            _c("base-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.mapViolations,
                  expression: "modelValidations.mapViolations",
                },
              ],
              attrs: {
                label: "High MAP",
                name: "high map violation",
                placeholder: "Enter high MAP",
                error: _vm.getError("high map violation"),
              },
              model: {
                value: _vm.model.high,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "high", $$v)
                },
                expression: "model.high",
              },
            }),
          ]
        : _vm._e(),
      _vm.model.fields && _vm.model.fields.length
        ? _c(
            "div",
            { staticClass: "custom-fields-collapse-wrapper" },
            [
              _c(
                "el-collapse",
                {
                  staticClass: "custom-fields-collapse",
                  model: {
                    value: _vm.activeItem,
                    callback: function ($$v) {
                      _vm.activeItem = $$v
                    },
                    expression: "activeItem",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "Custom Fields", name: "1" } },
                    _vm._l(_vm.model.fields, function (field, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "row mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-sm-6" },
                            [
                              _c("base-input", {
                                attrs: {
                                  label: "Field name",
                                  name: "key",
                                  placeholder: "Enter field name",
                                  readonly: field.isReadonly,
                                  required: "",
                                },
                                model: {
                                  value: field.key,
                                  callback: function ($$v) {
                                    _vm.$set(field, "key", $$v)
                                  },
                                  expression: "field.key",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "col-sm-5" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-column" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: `custom-field--${index}` } },
                                  [_vm._v(" Field value ")]
                                ),
                                _c("rb-select-filter", {
                                  staticClass: "single-upload-select",
                                  attrs: {
                                    id: `custom-field--${index}`,
                                    placeholder: "Select or enter custom value",
                                    "allow-create": "",
                                    clearable: true,
                                    "default-first-option": true,
                                    options: _vm.customFieldOptions[field.key],
                                    "append-to-body": true,
                                  },
                                  model: {
                                    value: field.value,
                                    callback: function ($$v) {
                                      _vm.$set(field, "value", $$v)
                                    },
                                    expression: "field.value",
                                  },
                                }),
                                _c(
                                  "small",
                                  { staticClass: "form-text text-muted" },
                                  [
                                    _vm._v(
                                      " Type in a custom value and press 'Enter' "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-sm-1 align-self-center mt-2",
                            },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "Enter a field name and value before creating additional fields.",
                                    disabled:
                                      field.isReadonly &&
                                      field.key &&
                                      !!field.value,
                                    "open-delay": 500,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      index + 1 === _vm.model.fields.length
                                        ? _c("el-button", {
                                            attrs: {
                                              type: "success",
                                              size: "small",
                                              icon: "el-icon-plus",
                                              circle: "",
                                              disabled:
                                                !field.isReadonly &&
                                                (!field.key || !field.value),
                                            },
                                            on: { click: _vm.onAddCustomField },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              !field.isReadonly &&
                              index < _vm.model.fields.length - 1
                                ? _c("el-button", {
                                    attrs: {
                                      type: "danger",
                                      size: "small",
                                      icon: "el-icon-close",
                                      circle: "",
                                    },
                                    on: {
                                      click: () =>
                                        _vm.onRemoveCustomField(index),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }