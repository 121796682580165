/**
 * Mixin to handle making a union of source lists per service
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  methods: {
    /**
     * Create a union of n number of arrays.
     *
     * @param {Array} selectedServices Array of services selected
     * @param {Object} serviceSourceList Object of source lists by service
     */
    unionSourceList(selectedServices, serviceSourceList) {
      const filteredServices = {};

      selectedServices.forEach((service) => {
        filteredServices[service] = serviceSourceList[service];
      });

      const sourceOptions = [];
      const counts = {};
      const serviceKeys = Object.keys(filteredServices);
      const countMatch = serviceKeys.length;

      serviceKeys.forEach((service) => {
        filteredServices[service].forEach((source) => {
          if (!(source.source in counts)) {
            counts[source.source] = 1;
          } else {
            counts[source.source] += 1;
          }

          if (counts[source.source] === countMatch) {
            sourceOptions.push({
              label: source.name,
              value: source.source,
            });
          }
        });
      });

      // Sort options alphabetical a-z
      sourceOptions.sort((a, b) => a.value.localeCompare(b.value));

      return sourceOptions;
    },
  },
};
