var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c("div", { staticClass: "col-12 mb-3" }, [
      _c(
        "div",
        { staticClass: "d-flex flex-column" },
        [
          _c("label", { attrs: { for: "source-select" } }, [
            _vm._v(" Term source "),
          ]),
          _c(
            "el-select",
            {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: _vm.modelValidations.source,
                  expression: "modelValidations.source",
                },
              ],
              class: { invalid: _vm.getError("source") },
              attrs: {
                id: "source-select",
                name: "source",
                placeholder: "Select term source",
                filterable: "",
                clearable: "",
              },
              model: {
                value: _vm.model.source,
                callback: function ($$v) {
                  _vm.$set(_vm.model, "source", $$v)
                },
                expression: "model.source",
              },
            },
            _vm._l(_vm.sourceOptions, function (option, optionIndex) {
              return _c("el-option", {
                key: optionIndex,
                staticClass: "select-primary",
                attrs: {
                  label: option.label,
                  value: option.value,
                  disabled: option.disabled,
                },
              })
            }),
            1
          ),
          _vm.getError("source")
            ? _c("div", { staticClass: "form-text text-danger" }, [
                _c("small", [_vm._v(" The term source is required. ")]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "base-input",
          {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: _vm.modelValidations.listing,
                expression: "modelValidations.listing",
              },
            ],
            attrs: {
              label: "Term",
              name: "term",
              required: "",
              placeholder: "Enter Term",
              "tooltip-text":
                "A search term or phrase that a listing search will be performed on.",
              error: _vm.getError("term"),
            },
            model: {
              value: _vm.model.listing,
              callback: function ($$v) {
                _vm.$set(_vm.model, "listing", $$v)
              },
              expression: "model.listing",
            },
          },
          [
            _vm.getError("term")
              ? _c(
                  "div",
                  {
                    staticClass: "form-text text-danger",
                    attrs: { slot: "error" },
                    slot: "error",
                  },
                  [_c("small", [_vm._v(" The term field is required. ")])]
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
    _vm.model.fields && _vm.model.fields.length
      ? _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-collapse",
              {
                staticClass: "custom-fields-collapse",
                model: {
                  value: _vm.activeItem,
                  callback: function ($$v) {
                    _vm.activeItem = $$v
                  },
                  expression: "activeItem",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  { attrs: { title: "Custom Fields", name: "1" } },
                  _vm._l(_vm.model.fields, function (field, index) {
                    return _c("div", { key: index, staticClass: "row mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm-6" },
                        [
                          _c("base-input", {
                            attrs: {
                              label: "Field name",
                              name: "key",
                              readonly: field.isReadonly,
                              required: "",
                              placeholder: "Enter custom field name",
                            },
                            model: {
                              value: field.key,
                              callback: function ($$v) {
                                _vm.$set(field, "key", $$v)
                              },
                              expression: "field.key",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-column" },
                          [
                            _c(
                              "label",
                              { attrs: { for: `custom-field--${index}` } },
                              [_vm._v(" Field value ")]
                            ),
                            _c("rb-select-filter", {
                              staticClass: "single-upload-select",
                              attrs: {
                                id: `custom-field--${index}`,
                                placeholder: "Select or enter custom value",
                                "allow-create": "",
                                clearable: true,
                                "default-first-option": true,
                                options: _vm.customFieldOptions[field.key],
                                "append-to-body": true,
                              },
                              model: {
                                value: field.value,
                                callback: function ($$v) {
                                  _vm.$set(field, "value", $$v)
                                },
                                expression: "field.value",
                              },
                            }),
                            _c(
                              "small",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  " Type in a custom value and press 'Enter' "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-12 col-sm-1 align-self-center mt-2",
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content:
                                  "Enter a field name and value before creating additional fields.",
                                disabled:
                                  field.isReadonly &&
                                  field.key &&
                                  !!field.value,
                                "open-delay": 500,
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  index + 1 === _vm.model.fields.length
                                    ? _c("el-button", {
                                        attrs: {
                                          type: "success",
                                          size: "small",
                                          icon: "el-icon-plus",
                                          circle: "",
                                          disabled:
                                            !field.isReadonly &&
                                            (!field.key || !field.value),
                                        },
                                        on: { click: _vm.onAddCustomField },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          !field.isReadonly &&
                          index < _vm.model.fields.length - 1
                            ? _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  size: "small",
                                  icon: "el-icon-close",
                                  circle: "",
                                },
                                on: {
                                  click: () => _vm.onRemoveCustomField(index),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }