var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-10 mx-auto" },
        [
          _c(
            "simple-wizard",
            {
              attrs: {
                loading: _vm.isLoading,
                "finish-button-text": _vm.isBulk
                  ? "Upload listings"
                  : "Add listing",
                "prev-button-text": _vm.prevButtonText,
                "next-button-text": _vm.nextButtonText,
              },
              on: {
                "cancel-wizard": () => _vm.$router.push("/catalog-management"),
                "wizard-complete": _vm.handleSubmit,
                "update:startIndex": _vm.onStepChange,
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(" " + _vm._s(_vm.formHeader) + " "),
                ]),
              ]),
              _c(
                "wizard-tab",
                {
                  attrs: {
                    "before-change": () => _vm.validateStep("select-services"),
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _c("clipboard-check-outline-icon"),
                      _c("p", [_vm._v("Services")]),
                    ],
                    1
                  ),
                  _c("select-services", {
                    ref: "select-services",
                    on: { "on-validated": _vm.onStepValidated },
                  }),
                ],
                2
              ),
              !_vm.isBulk
                ? _c(
                    "wizard-tab",
                    {
                      attrs: {
                        "before-change": () =>
                          _vm.validateStep("single-upload-listing"),
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c("clipboard-text-outline-icon"),
                          _c("p", [_vm._v("Listing Info")]),
                        ],
                        1
                      ),
                      _c("single-upload-listing", {
                        ref: "single-upload-listing",
                        attrs: { "selected-services": _vm.selectedServices },
                        on: { "on-validated": _vm.onStepValidated },
                      }),
                      _vm.uploadError
                        ? _c("el-alert", {
                            staticClass: "mt-3",
                            attrs: {
                              type: "error",
                              title: "Unable to add listing",
                              description: _vm.uploadError,
                              closable: false,
                              "show-icon": "",
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isBulk
                ? _c(
                    "wizard-tab",
                    {
                      attrs: {
                        "before-change": () =>
                          _vm.validateStep("bulk-upload-listing"),
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c("cloud-upload-outline-icon"),
                          _c("p", [_vm._v("Upload")]),
                        ],
                        1
                      ),
                      _c("bulk-upload-listing", {
                        ref: "bulk-upload-listing",
                        attrs: { "selected-services": _vm.selectedServices },
                        on: { "on-validated": _vm.onStepValidated },
                      }),
                      _vm.uploadError
                        ? _c("el-alert", {
                            staticClass: "mt-3",
                            attrs: {
                              type: "error",
                              title: "Unable to upload listings",
                              description: _vm.uploadError,
                              closable: false,
                              "show-icon": "",
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "text-center mt-3" },
            [
              _vm._v(" Need more help? "),
              _c(
                "el-button",
                {
                  staticClass: "text-info",
                  attrs: { type: "text" },
                  on: { click: _vm.handleHelp },
                },
                [_vm._v(" Read about managing your catalog. ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }